<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <product-selector
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PRODUCTS)"
          :filterOrganization="stockMovement.organization.id"
          :filterWarehouse="getWarehouse()"
          @productChanged="(productId) => (selectedProduct = productId)"
        />
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="table-responsive align-items-center table-flush stock-movement-product-list"
            header-row-class-name="thead-light"
            :data="filteredStockMovementItemsModel"
            type="expand"
          >
            <div slot="empty" class="table-loading" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column
              :label="$t('COMMON.PRODUCT')"
              prop="product.name"
              min-width="80px"
            >
              <template v-slot="{ row }">
                <base-input v-if="row.id < 0">
                  <product-selector
                    :filterOrganization="stockMovement.organization.id"
                    :product="stockMovementItemsModel[row.id].storable.id"
                    :filterWarehouse="getWarehouse()"
                    :allowNone="false"
                    :showAll="false"
                    :disabled="row.id > 0"
                    @productChanged="
                      (productId, product) => {
                        stockMovementItemsModel[row.id].storable.id = productId;
                      }
                    "
                  />
                </base-input>
                <object-link
                  v-if="row.storable && row.id > 0"
                  :object="row.storable"
                />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.QUANTITY')"
              prop="quantity"
              min-width="40px"
            >
              <template v-slot="{ row }">
                <base-input
                  v-if="stockMovement.status == STATUS_DRAFT"
                  class="my-5"
                  :placeholder="$t('COMMON.QUANTITY')"
                  v-model="stockMovementItemsModel[row.id].quantity"
                  input-classes="form-control-alternative"
                />
                <span v-if="stockMovement.status != STATUS_DRAFT">
                  {{ stockMovementItemsModel[row.id].quantity }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="20px"
              v-if="stockMovement.movement_type != TYPE_CORRECT"
            >
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  content="Delete"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_EDIT_PRODUCTS)"
                >
                  <a
                    type="text"
                    @click="deleteStockMovementItem(row)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fas fa-trash"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div
        class="row mt-3 add-product"
        v-if="stockMovement.status == STATUS_DRAFT"
      >
        <base-button
          class="btn-sm add-product-button"
          @click="
            () => {
              addLine();
            }
          "
          v-if="stockMovement.movement_type != TYPE_CORRECT"
        >
          <i class="fal fa-plus"></i>
          {{ $t("PRODUCTS.ADD_PRODUCT") }}
        </base-button>
        <base-button
          class="btn-sm add-product-save"
          @click="savestockMovementItems()"
        >
          <i class="fas fa-save"></i>
          {{ $t("COMMON.SAVE") }}
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import {
  TYPE_CORRECT,
  TYPE_ADD,
  TYPE_REMOVE,
  TYPE_MOVE,
  STATUS_DRAFT,
} from "@/constants/stockMovements";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ProductSelector from "@/components/ProductSelector.vue";

export default {
  name: "product-list-table",

  components: {
    ProductSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    stockMovement: {
      type: Object,
      default: null,
      description: "StockMovement",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      filteredStockMovementItemsModel: [],
      loading: false,
      selectedProduct: null,
      stockMovementItemsModel: {},
      deletedStockMovementItems: {},
      TYPE_CORRECT: TYPE_CORRECT,
      STATUS_DRAFT: STATUS_DRAFT,
    };
  },

  computed: {},

  watch: {
    stockMovement: {
      handler: "refreshStockMovementItems",
      immediate: true,
    },
    selectedProduct: {
      handler: "filterStockMovementItems",
      immediate: true,
    },
  },

  mounted() {
    this.refreshStockMovementItems();
  },

  methods: {
    getWarehouse() {
      if (this.stockMovement.movement_type == TYPE_CORRECT) {
        return this.stockMovement.sourceWarehouse.id;
      } else if (this.stockMovement.movement_type == TYPE_ADD) {
        return this.stockMovement.destinationWarehouse.id;
      } else if (this.stockMovement.movement_type == TYPE_MOVE) {
        return this.stockMovement.sourceWarehouse.id;
      } else if (this.stockMovement.movement_type == TYPE_REMOVE) {
        return this.stockMovement.sourceWarehouse.id;
      }
    },

    async filterStockMovementItems() {
      this.filteredStockMovementItemsModel = Object.values(
        this.stockMovementItemsModel
      ).filter((item) => {
        if (item.id < 0) {
          return true;
        }
        if (this.selectedProduct) {
          if (this.selectedProduct != item.storable.id) {
            return false;
          }
        }
        return true;
      });
    },

    async deleteStockMovementItem(row) {
      if (row.id > 0) {
        const confirmation = await swal.fire({
          text: this.$t("STOCK_MOVEMENTS.DELETE_THIS_STOCK_MOVEMENT_PRODUCT"),
          type: "question",
          customClass: {
            popup: "delete-popup",
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value !== true) {
          return;
        }
      }

      this.deletedStockMovementItems[row.id] = _.cloneDeep(row);
      delete this.stockMovementItemsModel[row.id];
      this.filterStockMovementItems();
    },

    async savestockMovementItems() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        for (const line of Object.values(this.stockMovementItemsModel)) {
          const stockMovementItemData = _.cloneDeep(line);
          if (line.id > 0) {
            await this.$store.dispatch(
              "stockMovementItems/update",
              stockMovementItemData
            );
          } else {
            if (stockMovementItemData.storable.id) {
              delete stockMovementItemData.id;
              await this.$store.dispatch(
                "stockMovementItems/add",
                stockMovementItemData
              );
            }
          }
        }
        for (const line of Object.values(this.deletedStockMovementItems)) {
          if (line.id > 0) {
            await this.$store.dispatch("stockMovementItems/destroy", line.id);
          }
        }
        this.deletedStockMovementItems = {};
        this.$notify({
          type: "success",
          message: this.$t("PRODUCTS.PRODUCTS_SAVED"),
        });
        this.$emit("stockMovementItemsUpdated");
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
      }
    },

    addLine() {
      let newLinesIds = Object.values(this.stockMovementItemsModel)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;

      const newStockMovementItem = {
        type: "stock-movement-items",
        id: newLineId,
        quantity: 0,
        relationshipNames: ["storable", "stockMovement"],
        storable: {
          type: "products",
          id: null,
        },
        stockMovement: {
          type: "stock-movements",
          id: this.stockMovement.id,
        },
      };
      this.stockMovementItemsModel[newLineId] =
        _.cloneDeep(newStockMovementItem);
      this.filterStockMovementItems();
    },

    refreshStockMovementItems() {
      const stockMovementItemsModelData = {};
      for (const item of this.stockMovement.items) {
        stockMovementItemsModelData[item.id] = {
          type: "stock-movement-items",
          id: item.id,
          quantity: item.quantity,
          relationshipNames: ["storable", "stockMovement"],
          storable: {
            type: item.storable.type,
            id: item.storable.id,
            name: item.storable.name,
          },
          stockMovement: {
            type: "stock-movements",
            id: item.stockMovement.id,
          },
        };
      }
      this.stockMovementItemsModel = stockMovementItemsModelData;
      this.filterStockMovementItems();
    },
  },
};
</script>

<style>
.stock-movement-product-list .el-table__row .form-group {
  margin-bottom: 0;
}
.stock-movement-product-list .form-group.taxes-selector .remove-taxes-button {
  display: flex;
  align-items: center;
}
</style>
