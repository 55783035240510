var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elite-tabs-wrapper-content"},[_c('div',{staticClass:"title-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',[_vm._v(_vm._s(_vm.stockMovement.code))])]),_c('div',{staticClass:"col-6 text-right"},[(
            _vm.$currentUserCan(_vm.$permissions.PERM_DELETE_STOCK_MOVEMENTS) &&
            _vm.stockMovement.status === _vm.STATUS_DRAFT
          )?_c('base-button',{staticClass:"btn-outline-danger",attrs:{"icon":"","size":"sm"},on:{"click":_vm.deleteStockMovement}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-trash-alt"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DELETE"))+" ")])]):_vm._e(),(
            _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_STOCK_MOVEMENTS) &&
            _vm.stockMovement.status === _vm.STATUS_DRAFT
          )?_c('base-button',{staticClass:"elite-button add",attrs:{"icon":"","size":"sm"},on:{"click":_vm.editStockMovement}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-edit"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.EDIT"))+" ")])]):_vm._e()],1)])]),_c('div',{staticClass:"all-infos"},[_c('div',{staticClass:"col-12"},[_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.CREATED_AT")))]),_c('dd',[_vm._v(" "+_vm._s(_vm.$formatDate(_vm.stockMovement.created_at))+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.LOCATIONS")))]),_c('dd',[_c('locations',{attrs:{"locations":_vm.stockMovement.allowedLocations}})],1)]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.ORGANIZATION")))]),_c('dd',[_c('organization',{attrs:{"organization":_vm.stockMovement.organization}})],1)]),(_vm.stockMovement.sourceWarehouse)?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.SOURCE_WAREHOUSE")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.stockMovement.sourceWarehouse}})],1)]):_vm._e(),(_vm.stockMovement.destinationWarehouse)?_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.DESTINATION_WAREHOUSE")))]),_c('dd',[_c('object-link',{attrs:{"object":_vm.stockMovement.destinationWarehouse}})],1)]):_vm._e(),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.TYPE")))]),_c('dd',[_c('stock-movement-movement-type-badge',{attrs:{"stockMovement":_vm.stockMovement}})],1)]),_c('dl',{staticClass:"row"},[_c('dt',[_vm._v(_vm._s(_vm.$t("COMMON.STATUS")))]),_c('dd',[_c('stock-movement-status-badge',{attrs:{"stockMovement":_vm.stockMovement}})],1)]),_c('dl',{staticClass:"row"},[(_vm.stockMovement.excerpt)?_c('dt',[_vm._v(" "+_vm._s(_vm.$t("COMMON.NOTE"))+" ")]):_vm._e(),(_vm.stockMovement.excerpt)?_c('dd',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.stockMovement.excerpt)}})]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }